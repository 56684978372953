@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'MinervaModern';
    src: url('../src/assets/font/minervamodernregular.woff2') format('woff2'),
      url('../src/assets/font/minervamodernregular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    /* Font is local because there are no font API's with this specific font. */
  }
}


html {
  scroll-behavior: smooth;
}


body {
  background-color: #f5f5f5;
  font-family: "MinervaModern";
}